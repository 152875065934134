<template>
    <div class="loginpage">
        <img class="loginpage__background">
        <div class="loginpage__content">
            <div class="container">
                <div class="row">
                    <div class="col-sm-10 d-flex align-items-center header">
                        <img class="logo" src="./../assets/images/logo-lely.svg" alt="Lely logo"/>
                        <slot name="header"/>
                    </div>
                </div>
                <div class="row">
                    <div class="col-sm-10">
                        <div class="loginpage__title-holder">
                            <slot/>
                        </div>
                    </div>
                </div>
                <div class="row">
                    <div class="col-sm-10">
                        <div class="loginpage__form">
                            <form v-if="credentials" @submit.prevent="loginByCredentials()">
                                <div class="alert alert-info" role="alert" v-if="hasError">
                                    <div class="alert-body">
                                        <i class="lely-icon-warning"></i>
                                        {{ notFoundMessage }}
                                    </div>
                                </div>
                                <div class="form-group">
                                    <label class="text-white" for="email">{{ emailLabel }}</label>
                                    <input id="email" class="form-control" type="email" v-model="email" required
                                           :disabled="loading"
                                           data-testid="email"
                                           autocomplete="username"
                                           autofocus ref="email">
                                </div>
                                <div class="form-group">
                                    <label class="text-white" for="password">{{ passwordLabel }}</label>
                                    <b-input-group>
                                        <input id="password"
                                               class="form-control"
                                               :disabled="loading"
                                               :type="passwordShown ? 'text': 'password'"
                                               v-model="password"
                                               data-testid="password"
                                               autocomplete="current-password"
                                               required>

                                        <template #append>
                                            <b-btn @click="passwordShown = !passwordShown">
                                                <i :class="{'lely-icon-hide': passwordShown, 'lely-icon-show': !passwordShown}"
                                                   class="password-shown-icon m-0"/>
                                            </b-btn>
                                        </template>
                                    </b-input-group>
                                </div>
                                <div class="d-flex justify-content-end">
                                    <button
                                        class="btn btn-primary btn-primary--dark loginpage__btn m-0"
                                        data-testid="submit"
                                        type="submit"
                                        value="submit"
                                        :disabled="loading || loading"
                                    >
                                        <b-spinner v-if="loading" class="mr-2" small/>
                                        {{ submitLabel }}
                                    </button>
                                </div>
                            </form>
                            <form v-if="sso" class="my-2" @submit.prevent="initSso()">
                                <hr/>
                                <div class="d-flex justify-content-end">
                                    <b-btn type="submit" class="btn sso" :disabled="loading">
                                        <b-spinner v-if="loading" class="mr-2" small/>
                                        <img
                                            alt="logo of microsoft"
                                            src="data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHZpZXdCb3g9IjAgMCAyMyAyMyI+CiAgICA8cGF0aCBmaWxsPSIjZjNmM2YzIiBkPSJNMCAwaDIzdjIzSDB6Ii8+CiAgICA8cGF0aCBmaWxsPSIjZjM1MzI1IiBkPSJNMSAxaDEwdjEwSDF6Ii8+CiAgICA8cGF0aCBmaWxsPSIjODFiYzA2IiBkPSJNMTIgMWgxMHYxMEgxMnoiLz4KICAgIDxwYXRoIGZpbGw9IiMwNWE2ZjAiIGQ9Ik0xIDEyaDEwdjEwSDF6Ii8+CiAgICA8cGF0aCBmaWxsPSIjZmZiYTA4IiBkPSJNMTIgMTJoMTB2MTBIMTJ6Ii8+Cjwvc3ZnPgo="/>
                                        {{ ssoLabel }}
                                    </b-btn>
                                </div>
                            </form>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script lang="ts">
import { Component, Prop, Vue } from 'vue-property-decorator';
import LelyAuth from '../core/Auth';

@Component({})
export default class LelyLoginView extends Vue {

    @Prop({type: Boolean, default: true})
    sso!: boolean;

    @Prop({type: Boolean, default: false})
    credentials!: boolean;

    @Prop({default: 'E-mail'})
    emailLabel!: string;

    @Prop({default: 'Password'})
    passwordLabel!: string;

    @Prop({default: 'Sign in'})
    submitLabel!: string;

    @Prop({default: 'User not found'})
    notFoundMessage!: string;

    @Prop({default: 'Login for Lely employees'})
    ssoLabel!: string;

    email = '';
    password = '';
    hasError = false;
    loading = false;
    passwordShown = false;

    mounted(): void {
        if (this.credentials) {
            (this.$refs.email as HTMLInputElement).focus();
        }

        if (this.sso) {
            if (this.$route.query.access_token) {
                this.loading = true;
                this.loginByToken(String(this.$route.query.access_token));
                this.$route.query.access_token = '';
            } else if (this.$route.query.error) {
                this.loading = false;

                this.$bvModal.msgBoxOk([
                    this.$createElement('h3', `An error occured: ${this.$route.query.error}`),
                    this.$createElement('pre', {
                        attrs: {
                            style: 'white-space: pre-wrap'
                        }
                    }, this.$route.query.error_description),
                ]);
            } else if (!this.credentials) {
                this.initSso();
            } else {
                this.loading = false;
            }
        }
    }

    initSso(): void {
        this.loading = true;
        LelyAuth.initSso();
    }

    async loginByCredentials() {
        this.loading = true;

        try {
            const user = await LelyAuth.authenticateByCredentials(this.email, this.password)

            this.$emit('authenticated', { user, redirectTo: this.$route.query.redirect_to });
        } catch (err) {
            this.hasError = true;
        } finally {
            this.loading = false;
        }
    }

    async loginByToken(token: string) {
        const user = await LelyAuth.authenticateByToken(token);

        this.$emit('authenticated', {user, redirectTo: this.$route.query.redirect_to});
    }
}

</script>

<style lang="scss" scoped>
.loginpage__background {
    width: 100%;
    position: fixed;
    top: 0;
    right: 0;
    background-repeat: no-repeat;
    background-image: url(/images/login.jpg);
    background-size: cover;
}

.header {
    margin-top: 50px;
    margin-bottom: 25px;
}

.logo {
    height: 83px;
    margin-right: 25px;
}

.password-shown-icon:before {
    position: relative;
    top: 2px;
}

.sso {
    align-items: center;
    display: flex;

    img {
        height: 16px;
        transform: none;
        width: 16px;
    }
}
</style>
